import "../search.css";

const Search = () => {
  window.__gcse || (window.__gcse = {});
  window.__gcse.searchCallbacks = {
    image: {
      rendered: makenicer,
    },
    web: {
      rendered: makenicer,
    },
  };
  var title = "";
  function makenicer(name, q, promos, results, resultsDiv) {
    const elm = document.querySelector(".gsc-tabsArea");
    if (elm) {
      console.log("found elm:", elm);
      var url = new URL(document.location.href);
      var cq = url.hash.split("gsc.q");
      if (cq[1]) {
        let c = decodeURIComponent(q[1].slice(1));
        let a = c.split("&")[0];
        if (a !== title) document.title = a + " - Google Result";
        title = c;
      }
    }
  }
  return (
    <>
      <div class="cse-header">
        <img src="/google.png" alt="Google logo" height="30" width="92" />
        <div style={{ width: "22em", padding: "5px 9px 4px 19px" }}>
          <div class="gcse-searchbox"></div>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div class="gcse-searchresults"></div>
        <div style={{ flex: "40%" }}></div>
      </div>
    </>
  );
};

export default Search;
