import { Routes, Route } from "react-router-dom";
import SgpaToPercentage from "./Tools/SgpaToPercentage";
import Home from "./Page/Home";
import About from "./Page/About";
import Tools from "./Page/Tools";
import Contact from "./Page/Contact";
import PrivacyAndPolicy from "./Page/PrivacyAndPolicy";
import TermsAndConditions from "./Page/TermsAndConditions";
import Disclaimer from "./Page/Disclaimer";
import CGPAToPercentage from "./Tools/CGPAToPercentage";
import PercentageToCGPA from "./Tools/PercentageToCGPA";
import SgpaToCgpa from "./Tools/SgpaToCgpa";
import CGPAtoGPA from "./Tools/CGPAtoGPA";
import PercentageToGPA from "./Tools/PercentageToGPA";
import MarksToCgpa from "./Tools/MarksToCgpa";
import MarksToPercentage from "./Tools/MarksToPercentage";
import GPATo4ScaleConverter from "./Tools/GPATo4ScaleConverter";
import GPAToPercentageConverter from "./Tools/GPAToPercentageConverter";
import Search from "./Page/Search";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />}>
        {" "}
      </Route>
      <Route path="/about-us" element={<About />}>
        {" "}
      </Route>
      <Route path="/all-tools" element={<Tools />}>
        {" "}
      </Route>
      <Route path="/contact-us" element={<Contact />}>
        {" "}
      </Route>
      <Route path="/privacy-and-policy" element={<PrivacyAndPolicy />}>
        {" "}
      </Route>
      <Route path="/disclaimer" element={<Disclaimer />}>
        {" "}
      </Route>
      <Route path="/terms-and-conditions" element={<TermsAndConditions />}>
        {" "}
      </Route>
      <Route path="/cgpa-to-gpa-calculator" element={<CGPAtoGPA />}>
        {" "}
      </Route>
      <Route
        path="/cgpa-to-percentage-calculator"
        element={<CGPAToPercentage />}
      >
        {" "}
      </Route>
      <Route
        path="/gpa-to-4-scale-converter"
        element={<GPATo4ScaleConverter />}
      >
        {" "}
      </Route>
      <Route
        path="/gpa-to-percentage-calculator"
        element={<GPAToPercentageConverter />}
      >
        {" "}
      </Route>
      <Route path="/marks-to-cgpa-calculator" element={<MarksToCgpa />}>
        {" "}
      </Route>
      <Route
        path="/marks-to-percentage-calculator"
        element={<MarksToPercentage />}
      ></Route>
      <Route
        path="/percentage-to-cgpa-calculator"
        element={<PercentageToCGPA />}
      ></Route>
      <Route
        path="/percentage-to-gpa-calculator"
        element={<PercentageToGPA />}
      ></Route>
      <Route path="/sgpa-to-cgpa-calculator" element={<SgpaToCgpa />}></Route>
      <Route
        path="/sgpa-to-percentage-calculator"
        element={<SgpaToPercentage />}
      ></Route>

      <Route path="/search" element={<Search />} />
    </Routes>
  );
}

export default App;
