import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import './main.css'
import './Responsive.css'
import Sidebar from '../Components/Sidebar';
import Layout from '../Components/Layout';

const MarksToPercentage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  
  const [obtainedMarks, setObtainedMarks] = useState('');
  const [totalMarks, setTotalMarks] = useState('');
  const [percentage, setPercentage] = useState(null);
  const [history, setHistory] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  // Load history from localStorage on component mount
  useEffect(() => {
    const savedHistory = localStorage.getItem('marksToPercentageHistory');
    if (savedHistory) {
      setHistory(JSON.parse(savedHistory));
    }
  }, []);

  // Save history to localStorage whenever history state updates
  useEffect(() => {
    localStorage.setItem('marksToPercentageHistory', JSON.stringify(history));
  }, [history]);

 // Validation for marks
const validateMarks = () => {
  const obtained = parseFloat(obtainedMarks);
  const total = parseFloat(totalMarks);

  // Validate if input is not empty and obtained marks are less than or equal to total marks
  if (isNaN(obtained) || isNaN(total) || obtained < 0 || total <= 0 || obtained > total) {
    setErrorMessage('Please enter valid marks (Obtained marks cannot exceed total marks)');
    return false;
  }

  setErrorMessage('');
  return true;
};
//eslint-disable-next-line
const handleObtainedMarksChange = (e) => {
  setObtainedMarks(e.target.value);
  validateMarks(e.target.value, totalMarks); // Revalidate on change
};
//eslint-disable-next-line
const handleTotalMarksChange = (e) => {
  setTotalMarks(e.target.value);
  validateMarks(obtainedMarks, e.target.value); // Revalidate on change
};

  const handleConvert = () => {
    if (!validateMarks()) return;

    const result = (obtainedMarks / totalMarks) * 100;
    setPercentage(result.toFixed(2)); // Round off to 2 decimal places

    // Update the history state
    const newEntry = {
      date: new Date().toLocaleDateString(),
      obtainedMarks,
      totalMarks,
      percentage: result.toFixed(2),
    };

    setHistory((prevHistory) => [...prevHistory, newEntry]);
  };

  const handleClearHistory = () => {
    setHistory([]);
    setPercentage(null)
    localStorage.removeItem('marksToPercentageHistory');
  };

  // Function to export history to CSV
  const exportToCSV = () => {
    if (history.length === 0) return;

    const csvRows = [];
    const headers = ['Date', 'Obtained Marks', 'Total Marks', 'Percentage'];
    csvRows.push(headers.join(','));

    history.forEach((entry) => {
      const row = [entry.date, entry.obtainedMarks, entry.totalMarks, entry.percentage];
      csvRows.push(row.join(','));
    });

    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'marks_to_percentage_history.csv');
    a.click();
  };



  return (
    <Layout
      pageTitle="Marks to Percentage Calculator - SGPA2Percentage"
      metaDescription="Convert your marks to percentage using our simple and accurate Marks to Percentage calculator."
      metaKeywords="marks to percentage calculator, percentage conversion, academic tools, marks to percentage conversion"
      canonicalUrl="https://sgpa2percentage.com/marks-to-percentage-calculator"
      robotsContent="index, follow"
      ogTitle="Marks to Percentage Calculator - SGPA2Percentage"
      ogDescription="Convert your academic marks into percentage with our efficient Marks to Percentage calculator."
      ogUrl="https://sgpa2percentage.com/marks-to-percentage-calculator"
      ogImage="https://sgpa2percentage.com/img/logo.png"
      schemaData={{
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://sgpa2percentage.com/marks-to-percentage-calculator",
        "name": "Marks to Percentage Calculator",
        "description": "Easily convert your academic marks into percentage using our Marks to Percentage calculator.",
        "publisher": {
          "@type": "Organization",
          "name": "SGPA2Percentage",
          "logo": {
            "@type": "ImageObject",
            "url": "https://sgpa2percentage.com/img/logo.png"
          }
        },
        "author": {
    "@type": "Creator",
    "name": "Ramjee Chaurasiya",
    "dateModified": 20-10-2024  
}
      }}
     >
      <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-8 main-section'>
          <div className='container text-center'>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/all-tools" style={{ textDecoration: 'none', color: 'black' }}>All Tools</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">Marks To Percentage Calculator</li>
              </ol>
            </nav>
            <h1 className='text-center'>Marks To Percentage Calculator</h1>
            <p className='text-center'>Simplifying Marks to Percentage Conversion, One Click Away!</p>
            <div className='row'>
              <div className='col-md-12'>
                <label htmlFor='obtained' className='form-label label'>
                  <b>Enter Obtained Marks:</b> <span className='text-danger'>*</span>
                </label>
                <input
                  type="number"
                  className='form-control'
                  id='investmentPeriod'
                  value={obtainedMarks}
                  onChange={(e) => setObtainedMarks(e.target.value)}
                  placeholder="Obtained Marks"
                />
              </div>
            </div>
            <div className='row input-row'>
              <div className='col-md-12'>
                <label htmlFor='TotalMarks' className='form-label label'>
                  <b>Enter Total Marks:</b> <span className='text-danger'>*</span>
                </label>
                <input
                  type="number"
                  className='form-control'
                  id='annualInterestRate'
                  value={totalMarks}
                  onChange={(e) => setTotalMarks(e.target.value)}
                  placeholder="Total Marks"
                />
              </div>
            </div>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

            <div className='row input-row'>
              <div className='col-md-12'>
              <button className='btn btn-primary' onClick={handleConvert} disabled={!obtainedMarks || !totalMarks}>
                  Convert to Percentage
                </button>

              </div>
            </div>
          </div>

          {percentage && (
            <div className="row input-row" style={{ backgroundColor: 'white', height: '60px' }}>
              <h3 className='text-center' style={{ marginTop: '20px', color: 'green' }}>Calculated Percentage: {percentage}%</h3>
            </div>
          )}

          {history.length > 0 && (
            <div className='container'>
              <div className='row'>
                <div className='col-md-6'>
                  <h3 className='text-center'>Percentage History</h3>
                  {percentage && (
                    <Doughnut
                      data={{
                        labels: ['Percentage', 'Remaining'],
                        datasets: [
                          {
                            data: [percentage, 100 - percentage],
                            backgroundColor: ['#36A2EB', '#FFCE56'],
                          },
                        ],
                      }}
                    />
                  )}
                </div>

                <div className='col-md-6'>
                  <h3>Saved History</h3>
                  <div className='table-responsive'>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Obtained Marks</th>
                          <th>Total Marks</th>
                          <th>Percentage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {history.map((entry, index) => (
                          <tr key={index}>
                            <td>{entry.date}</td>
                            <td>{entry.obtainedMarks}</td>
                            <td>{entry.totalMarks}</td>
                            <td>{entry.percentage}%</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <button className='btn btn-danger' onClick={handleClearHistory}>Clear History</button>
                    <button className='btn btn-success' onClick={exportToCSV} style={{ marginLeft: '10px' }}>
                      Export to CSV
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className='container'>
              <div className='row' style={{marginTop:'50px'}}>
               <hr></hr>
               <h2 className='text-center text-black'>About Marks to Percentage Calculator</h2>
               <hr></hr>
               <p style={{fontFamily:'sans-serif'}}>The Marks to Percentage Calculator is a handy tool designed to convert raw marks into a percentage format. Percentages are a common way to represent academic performance, and this calculator provides an easy and accurate method to translate your marks into a percentage score, which is often used for academic evaluations, results, and comparisons.</p>
               <h3>What is a Percentage?</h3>
               <p>A percentage is a way of expressing a number as a fraction of 100. It represents how much out of the total possible marks a student has achieved. For example, if you scored 75 out of 100 in a test, your percentage score would be 75%.</p>
               <h3>Why Convert Marks to Percentage?</h3>
               <div style={{fontFamily:'sans-serif'}}>
                <ul>
                  <li>Standard Representation: Percentages offer a standardized method for representing and comparing academic performance.</li>
                  <li>Evaluation and Assessment: Percentages are commonly used in academic institutions to evaluate students' performance and determine grades.</li>
                  <li>Application and Admission: Many educational institutions and scholarships use percentage scores for admissions and selection processes.</li>
                  <li>Progress Tracking: Tracking percentage scores helps in monitoring academic progress over time.</li>
                </ul>
               </div>
               <h4>Features of the Marks to Percentage Calculator</h4>
               <div style={{fontFamily:"sans-serif"}}>
                <ul>
                  <li>Simple Calculation: Converts raw marks into percentage with minimal effort.</li>
                  <li>User-Friendly Interface: Easy to use, with clear input fields for obtained and total marks.</li>
                  <li>Accurate Results: Provides precise percentage scores based on the entered data.</li>
                  <li>Instant Feedback: Calculates and displays results immediately after input.</li>
                </ul>
               </div>
               <h4>Benefits of Using the Marks to Percentage Calculator</h4>
               <div style={{fontFamily:'sans-serif'}}>
                <ul>
                  <li>Easy Conversion: Quickly convert raw marks to percentages without complex calculations.</li>
                  <li>Standardized Performance Measurement: Understand and communicate your performance in a universally recognized format.</li>
                  <li>Academic Planning: Helps in evaluating performance and planning for future academic goals.</li>
                  <li>Application Readiness: Prepare accurate percentage scores for applications, reports, and other formal uses.</li>
                </ul>
               </div>
               <h5>Who Should Use This Calculator?</h5>
               <div style={{fontFamily:'sans-serif'}}>
                <ul>
                  <li>Students who need to convert their exam or assignment marks into a percentage for better understanding and reporting of their academic performance.</li>
                  <li>Educators who require a quick way to translate students’ marks into percentages for grading and assessments.</li>
                  <li>Parents who wish to track and understand their children’s academic progress in percentage terms.</li>
                  <li>Academic Advisors who need to evaluate student performance and provide guidance based on percentage scores.</li>
                </ul>
               </div>

               <h6><strong>Start Using the Marks to Percentage Calculator</strong></h6>
               <p style={{fontFamily:'sans-serif'}}>The Marks to Percentage Calculator simplifies the process of converting raw marks into percentage scores, helping you easily interpret and present academic performance. Whether you're a student, educator, or parent, this tool provides a convenient solution for evaluating and understanding marks in percentage format.</p>
               </div>
               </div>

        </div>
        <div className='col-md-3'>
          <Sidebar/>
        </div>
      </div>
    </div>
    </Layout>
  );
};

export default MarksToPercentage;
