import React, { useState , useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';  // Necessary to auto-register chart components
import { Link } from 'react-router-dom';

import './main.css';
import './Responsive.css';
import Sidebar from '../Components/Sidebar';
import Layout from '../Components/Layout';

function GpaConverter() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [gpa, setGpa] = useState('');
  const [scale, setScale] = useState('');
  const [convertedGpa, setConvertedGpa] = useState(null);
  const [history, setHistory] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const handleConversion = () => {
    setErrorMessage(''); // Reset error message

    // Validation: Ensure GPA and scale are valid
    if (!gpa || isNaN(gpa) || gpa <= 0) {
      setErrorMessage('Please enter a valid GPA greater than 0.');
      return;
    }
    if (!scale) {
      setErrorMessage('Please select a valid grading scale.');
      return;
    }
    if (scale === '10' && (gpa < 0 || gpa > 10)) {
      setErrorMessage('Please enter a GPA between 0 and 10 for the 10-point scale.');
      return;
    }
    if (scale === '5' && (gpa < 0 || gpa > 5)) {
      setErrorMessage('Please enter a GPA between 0 and 5 for the 5-point scale.');
      return;
    }

    let gpaOnFourScale = 0;

    if (scale === '10') {
      gpaOnFourScale = (gpa / 10) * 4;
    } else if (scale === '5') {
      gpaOnFourScale = (gpa / 5) * 4;
    }

    const finalGpa = gpaOnFourScale.toFixed(2);
    setConvertedGpa(finalGpa);

    // Add result to history
    const newHistoryItem = { inputGpa: gpa, scale, convertedGpa: finalGpa };
    setHistory([...history, newHistoryItem]);
  };

  const handleClearHistory = () => {
    setHistory([]);
    setConvertedGpa(null);
    localStorage.removeItem("sgpaHistory");
  };

  // Data for the Doughnut chart
  const doughnutData = {
    labels: ['GPA', 'Remaining'],
    datasets: [
      {
        data: [convertedGpa ? parseFloat(convertedGpa) : 0, 4 - (convertedGpa ? parseFloat(convertedGpa) : 0)],
        backgroundColor: ['#36A2EB', '#FF6384'],
        hoverBackgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  return (
    <Layout 

pageTitle="GPA to 4.0 Scale Converter - SGPA2Percentage"
metaDescription="Convert your GPA to the 4.0 scale with our easy-to-use GPA to 4.0 scale converter."
metaKeywords="GPA to 4.0 scale, GPA conversion, academic tools"
canonicalUrl="https://sgpa2percentage.com/gpa-to-4-scale-converter"
robotsContent="index, follow"
ogTitle="GPA to 4.0 Scale Converter - SGPA2Percentage"
ogDescription="Convert GPA to a 4.0 scale quickly using our academic converter tool."
ogUrl="https://sgpa2percentage.com/gpa-to-4-scale-converter"
ogImage="https://sgpa2percentage.com/img/logo.png"
schemaData={{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": "https://sgpa2percentage.com/gpa-to-4-scale-converter",
  "name": "GPA to 4.0 Scale Converter",
  "description": "Use our converter tool to easily translate GPA to a 4.0 scale.",
  "publisher": {
    "@type": "Organization",
    "name": "SGPA2Percentage",
    "logo": {
      "@type": "ImageObject",
      "url": "https://sgpa2percentage.com/img/logo.png"
    }
  },
  "author": {
    "@type": "Creator",
    "name": "Ramjee Chaurasiya",
    "dateModified": 20-10-2024  
}
}}

    >
          <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-1'></div>
        <div className='col-md-8 main-section'>
          <div className='container text-center'>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/all-tools" style={{ textDecoration: 'none', color: 'black' }}>All Tools</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">GPA To 4 Scale Calculator</li>
              </ol>
            </nav>

            <h1 className='text-center'>GPA To 4 Scale Calculator</h1>
            <p className='text-center'>Transform Your SGPA into 4 Scale with Ease!</p>

            {errorMessage && <p className="text-danger">{errorMessage}</p>}

            <div className='row'>
              <div className='col-md-12'>
                <label htmlFor='GPA' className='form-label label'><b>Enter GPA :</b> <span className='text-danger'>*</span></label>
                <input
                  type="number"
                  className='form-control'
                  id='investmentPeriod'
                  value={gpa}
                  onChange={(e) => setGpa(e.target.value)}
                  placeholder="Enter your GPA"
                />
              </div>
            </div>
            <div className='row input-row'>
              <div className='col-md-12'>
                <label htmlFor='gradingscale' className='form-label label'><b>Select Grading Scale :</b> <span className='text-danger'>*</span></label>
                <select
                  id='annualInterestRate'
                  className='form-control'
                  value={scale}
                  onChange={(e) => setScale(e.target.value)}
                >
                  <option value="">Select Scale</option>
                  <option value="10">10-point GPA scale</option>
                  <option value="5">5-point GPA scale</option>
                </select>
              </div>
            </div>
            <div className='row input-row'>
              <div className='col-md-12'>
                <button className='btn btn-primary' onClick={handleConversion}>Convert</button>
              </div>
            </div>
          </div>

          {convertedGpa && (
            <div className='row input-row' style={{ backgroundColor: "white", height: '60px' }}>
              <h3 className='text-center' style={{ marginTop: '20px', color: 'green' }}>Converted GPA on 4.0 scale: {convertedGpa}</h3>
            </div>
          )}

          {convertedGpa && (
            <div className='container'>
              <div className='row'>
                <div className='col-md-6'>
                  <div style={{ width: '300px', height: '300px' }}>
                    <Doughnut data={doughnutData} />
                  </div>
                </div>
                <div className='col-md-6'>
                  <h3 className='text-center'>Saved History</h3>
                  {history.length > 0 && (
                    <div className='table-responsive'>
                      <table className='table table-striped'>
                        <thead>
                          <tr>
                            <th>Input GPA</th>
                            <th>Scale</th>
                            <th>Converted GPA</th>
                          </tr>
                        </thead>
                        <tbody>
                          {history.map((item, index) => (
                            <tr key={index}>
                              <td>{item.inputGpa}</td>
                              <td>{item.scale === '10' ? '10-point scale' : '5-point scale'}</td>
                              <td>{item.convertedGpa}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <button onClick={handleClearHistory} className="btn btn-danger mt-3">Clear History</button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className='container'>
            <div className='row' style={{marginTop:"50px"}}>
              <hr></hr>
              <h2 className='text-center text-black'>About GPA to 4 Scale Calculator </h2>
              <hr></hr>
              <p style={{fontFamily:'sans-serif'}}>The GPA to 4 Scale Calculator is a tool designed to convert your GPA from various grading scales, such as the 5-point and 10-point systems, into the commonly used 4-point GPA scale. This conversion is essential for students applying to universities, scholarships, or employment opportunities in countries where the 4-point scale is the standard, such as the United States and Canada. By using this tool, students can easily and accurately compare their academic performance with global standards.</p>
              <h3>Why is GPA Conversion Important?</h3>
              <p style={{fontFamily:'sans-serif'}}>A GPA on a 4.0 scale provides a universal benchmark for comparing academic performance across various education systems. Since educational systems around the world use different scales for grading, converting a GPA from one scale to another allows for an apples-to-apples comparison. For instance, while some countries or institutions use a 10-point scale or a 5-point scale, many universities, particularly in North America, evaluate applicants using the 4.0 scale. As a result, having a standardized conversion ensures that admissions officers can fairly evaluate the academic standing of students from diverse educational backgrounds.</p>
              <h3>What is the 4.0 GPA Scale?</h3>
              <p style={{fontFamily:"sans-serif"}}>The 4.0 GPA scale is one of the most commonly used grading systems, especially in the United States. Under this system:</p>
              <div style={{fontFamily:"sans-serif"}}>
                <ul>
                  <li>A GPA of 4.0 typically represents a perfect academic score, equating to an "A" or 90-100% grade.</li>
                  <li>A GPA of 3.0 is equivalent to a "B" or an average grade between 80-89%.</li>
                  <li>A GPA of 2.0 signifies a "C," while a GPA below 2.0 reflects lower academic performance.</li>
                </ul>
              </div>
               <h4>Why Convert to a 4.0 Scale?</h4>
               <p style={{fontFamily:"sans-serif"}}>Educational institutions across different countries and regions use various grading scales, such as the 10.0 scale, percentage (100) scale, or even 5.0 scale. When applying for higher education, internships, or jobs in regions where the 4.0 GPA scale is required, converting your GPA becomes essential for a fair evaluation of your academic performance.</p>
               <h5>How the GPA to 4.0 Scale Calculator Works</h5>
               <p style={{fontFamily:'sans-serif'}}>This calculator allows you to convert GPAs from the following common scales to the 4.0 scale:</p>
               <div style={{fontFamily:'sans-serif'}}>
                <ul>
                  <li>10.0 Scale: Commonly used in certain educational systems worldwide.</li>
                  <li>5.0 Scale: Another GPA system used by various institutions.</li>
                  <li>100 (Percentage) Scale: A system where GPA is represented as a percentage, commonly used in many high schools or international educational systems.</li>
                </ul>
                <h5>How to Use</h5>
                <div style={{fontFamily:'sans-serif'}}>
                  <ul>
                    <li><b>Enter Your GPA:</b> Input your GPA value according to your current scale.</li>
                    <li><b>Select Your Grading Scale:</b> Choose the original scale (e.g., 10.0, 5.0, or 100% scale) from which you want to convert.</li>
                    <li><b>Convert to 4.0 Scale:</b> Click the “Convert” button to instantly see your GPA on the 4.0 scale.</li>
                  </ul>
                </div>
               </div>
               <h5>Features of the GPA to 4.0 Scale Calculator</h5>
               <div style={{fontFamily:'sans-serif'}}>
                <ul>
                  <li>Fast and Accurate Conversion: Converts your GPA to a 4.0 scale in seconds.</li>
                  <li>Supports Multiple Grading Systems: Whether you're on a 5.0 scale, 10.0 scale, or percentage system, the calculator converts it to 4.0 for you.</li>
                  <li>Conversion History: Keep track of past conversions, allowing you to compare results and store important data for future reference.</li>
                </ul>
               </div>
               <h5>Why Use This Tool?</h5>
               <p style={{fontFamily:'sans-serif'}}>Converting your GPA to the 4.0 scale allows you to apply to academic programs, scholarships, and jobs that specifically require a GPA in this format. By using the GPA to 4.0 Scale Calculator, you can ensure your academic performance is represented fairly and consistently across different systems.</p>
            </div>
          </div>
        </div>
        <div className='col-md-3'>
          <Sidebar />
        </div>
      </div>
    </div>
    </Layout>
  );
}

export default GpaConverter;
