import React , {useEffect} from 'react'
import Layout from '../Components/Layout'
import { Link } from 'react-router-dom'

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout
      pageTitle="All In One  SGPA to Percentage Calculators"
      metaDescription="Convert your sgpa to percentage using our simple and accurate sgpa to percentage calculator."
      metaKeywords="sgpa to percentage, sgpa into percentage, academic tools, sgpa  to  percentage conversion"
      canonicalUrl="https://sgpa2percentage.com/"
      robotsContent="index, follow"
      ogTitle="SGPA to Percentage Calculators"
      ogDescription="Convert your sgpa to percentage using our simple and accurate sgpa to percentage calculator. Convert your academic CGPA into GPA quickly."
      ogUrl="https://sgpa2percentage.com/"
      ogImage="https://sgpa2percentage.com/img/logo.png"
      schemaData={{
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://sgpa2percentage.com/cgpa-to-gpa-calculator",
        "name": "CGPA to GPA Calculator",
        "description": "Convert your sgpa to percentage using our simple and accurate sgpa to percentage calculator",
        "publisher": {
          "@type": "Organization",
          "name": "SGPA2Percentage",
          "logo": {
            "@type": "ImageObject",
            "url": "https://sgpa2percentage.com/img/logo.png"
          }
        },
        "author": {
    "@type": "Creator",
    "name": "Ramjee Chaurasiya",
    "dateModified": 20-10-2024  
}
      }}
    
    
    >

<div className="container-fluid px-4 py-5 my-5 text-center curved">
	<div className="lc-block d-block mx-auto mb-4">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="5em" height="5em" lc-helper="svg-icon" fill="currentColor">
      <path d="M464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zm-16 320H64V128h384v256zm-64-160h-64v-64h64v64zm0-96h-64v-64h64v64zm-96 160h-64v-64h64v64zm0-96h-64v-64h64v64zm-96 160h-64v-64h64v64zm0-96h-64v-64h64v64zm-96 160H64v-64h64v64zm0-96H64v-64h64v64zm0-96H64v-64h64v64zm-32-96h-32V96h32v32zm0 64h-32v-32h32v32z"></path>
    </svg>
	</div>
	<div className="lc-block">
		<div editable="rich">

			<h2 className="display-5 fw-bold"><span style={{color:'#0B5ED7'}}> Sgpa2Percentage </span>- Your Trusted Converter!

</h2>

		</div>
	</div>
	<div className="lc-block col-lg-6 mx-auto mb-4">
		<div editable="rich">

			<p className="lead ">Are you a student trying to convert your SGPA into a percentage? Look no further! At SGPA2Percentage.com, we offer a fast and easy-to-use tool to help you accurately convert your Semester Grade Point Average (SGPA) into a percentage in just a few clicks.</p>

		</div>
	</div>

	<div className="lc-block d-grid gap-2 d-sm-flex justify-content-sm-center"> <Link className="btn btn-primary btn-lg px-4 gap-3" to="/all-tools" role="button">Get Tools</Link>
		<Link className="btn btn-outline-primary btn-lg px-4" to="/contact-us" role="button">Contact</Link>
	</div>
</div>

<div className='container'>
  <p className='text-center' style={{fontSize:'30px',marginBottom:'30px'}}>Why Use Our SGPA to Percentage Converter?</p>
  <div className='row'>
    <div className='col-md-3 mt-5'>
      <div className='card'>
        <div className='card-body'>
          <h5 className='card-title'>Accurate Results</h5>
          <p className='card-text'>Our converter follows the standardized formulas.</p>
        </div>
      </div>
    </div>


    <div className='col-md-3 mt-5'>
      <div className='card'>
        <div className='card-body'>
          <h5 className='card-title'> Easy-to-Use</h5>
          <p className='card-text'>Just input your SGPA, and get the equivalent percentage instantly.</p>
        </div>
      </div>
    </div>




    <div className='col-md-3 mt-5'>
      <div className='card'>
        <div className='card-body'>
          <h5 className='card-title'>Time-Saving</h5>
          <p className='card-text'>Avoid manual calculations and let our tool do the hard work for you.</p>
        </div>
      </div>
    </div>




    <div className='col-md-3 mt-5'>
      <div className='card'>
        <div className='card-body'>
          <h5 className='card-title'>Mobile-Friendly</h5>
          <p className='card-text'>Use the tool anytime, anywhere on any device.</p>
        </div>
      </div>
    </div>
  </div>


</div>
 
 {/* tools section  */}

 <div className='container-fluid' style={{marginTop:'40px'}}>
<p className='text-center' style={{fontSize:'30px',marginBottom:'10px'}}>Explore Our Calculators</p>
 <div className="container">
 
  <div className="row ">
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  <div className="card-body">
    <h5 className="card-title"><b>Percentage to CGPA Calculator</b></h5>
    <p className="card-text">Convert your percentage to CGPA easily with our efficient Percentage to CGPA Calculator. </p>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div class="card" style={{width:'18rem'}}>
  <div class="card-body">
    <h5 class="card-title"><b>Percentage to GPA Calculator</b></h5>
    <p class="card-text">Effortlessly convert your percentage to GPA with our simple and accurate calculator tool. </p>

  </div>
</div>
    </div>
    <div className="col mt-5">
    <div class="card" style={{width:'18rem'}}>
  <div class="card-body">
    <h5 class="card-title"><b>Marks to Percentage Calculator</b></h5>
    <p class="card-text">Accurately convert your marks to percentage with our simple Marks to Percentage Calculator.</p>

  </div>
</div>
    </div>
    <div className="col mt-5">
    <div class="card" style={{width:'18rem'}}>
  <div class="card-body">
    <h5 class="card-title"><b>SGPA to CGPA Calculator</b></h5>
    <p class="card-text">Accurately convert your SGPA to CGPA with ease using our reliable calculator tool.</p>

  </div>
</div>
    </div>
  </div>

  {/* second rows  */}

  <div className="row mt-5">
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  <div className="card-body">
    <h5 className="card-title"><b>SGPA to Percentage Calculator</b></h5>
    <p className="card-text">Convert your SGPA to Percentage quickly and accurately with our easy-to-use tool.</p>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  <div className="card-body">
    <h5 className="card-title"><b>GPA to Percentage Calculator</b></h5>
    <p className="card-text"> Convert your GPA to percentage format easily with our accurate GPA to Percentage Calculator.</p>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  <div className="card-body">
    <h5 className="card-title"><b>GPA to 4.0 Scale Converter</b></h5>
    <p className="card-text">Convert your GPA to a 4.0 scale quickly and accurately with our tool.</p>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  <div className="card-body">
    <h5 className="card-title"><b>CGPA to Percentage Calculator</b></h5>
    <p className="card-text">Convert your CGPA to Percentage easily and accurately with our efficient online calculator.</p>
  </div>
</div>
 </div>

  </div>
  
  
</div>
</div>
 


      
    </Layout>
  )
}

export default Home
