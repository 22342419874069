import React from 'react';
import Footer from './Footer';
import Header from './Header'
import { Helmet } from 'react-helmet';

const Layout = ({ 
  pageTitle, 
  metaDescription, 
  metaKeywords, 
  schemaData, 
  ogTitle, 
  ogDescription, 
  ogUrl, 
  ogImage, 
  canonicalUrl, 
  robotsContent,
  children 
}) => {
  return (
    <>
      <Helmet>
        {/* Page Title */}
        <title>{pageTitle}</title>

        {/* Meta Tags */}
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Canonical URL */}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

        {/* Robots Tag */}
        {robotsContent && <meta name="robots" content={robotsContent} />}

        {/* Open Graph Tags */}
        {ogTitle && <meta property="og:title" content={ogTitle} />}
        {ogDescription && <meta property="og:description" content={ogDescription} />}
        {ogUrl && <meta property="og:url" content={ogUrl} />}
        {ogImage && <meta property="og:image" content={ogImage} />}

        {/* Dynamic Schema.org Data */}
        {schemaData && (
          <script type="application/ld+json">
            {JSON.stringify(schemaData)}
          </script>
        )}

        {/* Twitter Cards for better social sharing */}
        {ogTitle && <meta name="twitter:title" content={ogTitle} />}
        {ogDescription && <meta name="twitter:description" content={ogDescription} />}
        {ogImage && <meta name="twitter:image" content={ogImage} />}
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Header /> {/* Rendering the Header component */}
      <main>{children}</main> {/* Rendering the child components passed to Layout */}
      <Footer /> {/* Rendering the Footer component */}
    </>
  );
};

export default Layout;
