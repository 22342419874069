import React from 'react'
import { Link } from 'react-router-dom'

const Sidebar = () => {
  return (
    <div>
      <div className='ads'>
        <p className='text-center'>ads</p>
      </div>

<div className='text'>
<h3 className='text-center' style={{color:'white', paddingTop:'0.5rem'}}>Important Tools</h3>
</div>
<div className='tools-list'>
 <ul>
  <li> <Link to='/cgpa-to-gpa-calculator' target="_blank">CGPA To GPA</Link></li>
  <li><Link to='/cgpa-to-percentage-calculator' target="_blank">CGPA To Percentage</Link></li>
  <li> <Link to='/gpa-to-4-scale-converter' target="_blank">GPA To 4 Scale</Link></li>
  <li><Link to='/gpa-to-percentage-calculator' target="_blank"> GPA to Percentage</Link></li>
  <li><Link to='/marks-to-cgpa-calculator' target="_blank">Marks To CGPA</Link></li>
  <li><Link to="/marks-to-percentage-calculator" target="_blank">Marks To Percentage</Link></li>
  <li><Link to="/percentage-to-cgpa-calculator" target="_blank">Percentage To CGPA</Link></li>
  <li><Link to="/percentage-to-gpa-calculator" target="_blank">Percentage to GPA</Link></li>
  <li><Link to="/sgpa-to-cgpa-calculator" target="_blank">SGPA To CGPA</Link></li>
  <li><Link to="/sgpa-to-percentage-calculator" target="_blank">SGPA To Percentage</Link></li>
   
  
   
   
 </ul>
</div>
    </div>
  )
}

export default Sidebar
