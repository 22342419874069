// src/components/SGPAtoCGPA.js
import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js';
import Layout from '../Components/Layout';
import './main.css';
import './Responsive.css';
import Sidebar from '../Components/Sidebar';

// Register the required Chart.js components
ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale);

const SGPAtoCGPA = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  
  const [sgpa, setSgpa] = useState('');
  const [scale, setScale] = useState('4.0'); // Default grading scale is 4.0
  const [cgpa, setCgpa] = useState(null);
  const [history, setHistory] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const savedHistory = localStorage.getItem('sgpaCgpaHistory');
    if (savedHistory) {
      setHistory(JSON.parse(savedHistory));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('sgpaCgpaHistory', JSON.stringify(history));
  }, [history]);

  const validateInput = () => {
    let maxScale = scale === '4.0' ? 4 : scale === '5.0' ? 5 : 10;
    if (sgpa === '' || isNaN(sgpa)) {
      setError('Please enter a valid SGPA.');
      return false;
    }
    if (sgpa < 0 || sgpa > maxScale) {
      setError(`SGPA must be between 0 and ${maxScale} for a ${scale} grading scale.`);
      return false;
    }
    setError('');
    return true;
  };

  const handleConvert = () => {
    if (!validateInput()) return;

    const numericSgpa = parseFloat(sgpa);
    let result = 0;

    if (scale === '4.0') {
      result = (numericSgpa / 4) * 10; // Assuming 4.0 SGPA to 10-point CGPA conversion
    } else if (scale === '5.0') {
      result = (numericSgpa / 5) * 10; // Assuming 5.0 SGPA to 10-point CGPA conversion
    } else if (scale === '10.0') {
      result = numericSgpa; // Assuming SGPA is already on a 10-point scale
    }

    setCgpa(result.toFixed(2));
    const newEntry = { sgpa: numericSgpa, cgpa: result.toFixed(2), scale, date: new Date().toLocaleString() };
    setHistory([...history, newEntry]);
  };

  const handleClearHistory = () => {
    setHistory([]);
    localStorage.removeItem('sgpaCgpaHistory');
  };

  const historyGraphData = {
    labels: history.map((entry) => entry.date),
    datasets: [
      {
        label: 'CGPA over Time',
        data: history.map((entry) => entry.cgpa),
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
        tension: 0.1,
      },
    ],
  };

  return (
    <Layout 

pageTitle="SGPA to CGPA Calculator - SGPA2Percentage"
metaDescription="Easily convert your SGPA to CGPA with our precise and user-friendly SGPA to CGPA calculator tool."
metaKeywords="SGPA to CGPA, SGPA to CGPA calculator, academic tools"
canonicalUrl="https://sgpa2percentage.com/sgpa-to-cgpa-calculator"
robotsContent="index, follow"
ogTitle="SGPA to CGPA calculator Tool - SGPA2Percentage"
ogDescription="Convert SGPA to CGPA efficiently with our online educational tool."
ogUrl="https://sgpa2percentage.com/sgpa-to-cgpa-calculator"
ogImage="https://sgpa2percentage.com/img/logo.png"
schemaData={{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": "https://sgpa2percentage.com/sgpa-to-cgpa-calculator",
  "name": "SGPA to CGPA calculator Tool",
  "description": "Use our SGPA to CGPA calculator tool to calculate your CGPA from SGPA values.",
  "publisher": {
    "@type": "Organization",
    "name": "SGPA2Percentage",
    "logo": {
      "@type": "ImageObject",
      "url": "https://sgpa2percentage.com/img/logo.png"
    }
  },
  "author": {
    "@type": "Creator",
    "name": "Ramjee Chaurasiya",
    "dateModified": 20-10-2024  
}
}}

    
    >
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-1'></div>
          <div className='col-md-8 main-section'>
            <div className='container'>
              <div className='bred'>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/all-tools" style={{ textDecoration: 'none', color: 'black' }}>All Tools</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">SGPA To CGPA Calculator</li>
                  </ol>
                </nav>
              </div>
              <h1 className='text-center'>SGPA To CGPA Calculator</h1>
              <p className='text-center'>Simplifying SGPA to CGPA Conversion, One Click Away!</p>

              <div className='container text-center'>
                <div className='row'>
                  <div className='col-md-12'>
                    <label htmlFor='SGPA' className='form-label label'>
                      <b>Enter SGPA: </b><span className='text-danger'>*</span>
                    </label>
                    <input
                      type="number"
                      className='form-control'
                      id='investmentPeriod'
                      step="0.01"
                      value={sgpa}
                      onChange={(e) => setSgpa(e.target.value)}
                      placeholder="Enter your SGPA"
                    />
                    {error && <p className="text-danger">{error}</p>}
                  </div>
                </div>
                <div className='row input-row'>
                  <div className='col-md-12'>
                    <label htmlFor='grading' className='form-label label'>
                      <b>Select Grading Scale: </b><span className='text-danger'>*</span>
                    </label>
                    <select
                      value={scale}
                      className='form-control'
                      id='annualInterestRate'
                      onChange={(e) => setScale(e.target.value)}>
                      <option value="4.0">4.0 Grading Scale</option>
                      <option value="5.0">5.0 Grading Scale</option>
                      <option value="10.0">10.0 Grading Scale</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className='row input-row'>
                <div className='col-md-12'>
                  <button
                    id='annualInterestRate'
                    className='btn btn-primary'
                    onClick={handleConvert}
                    disabled={sgpa === ''}>
                    Convert to CGPA
                  </button>
                </div>
              </div>
            </div>
            {cgpa && 
            <div className='row input-row' style={{backgroundColor:'white', height:'60px'}}><h3 className='text-center' style={{marginTop:'20px' , color:'green'}}>Calculated CGPA: {cgpa}</h3></div>}
            
      {history.length > 0 && (
        <div className='container'>
          <div className='row'>
          <div className='col-md-6'>
              {history.length > 0 && (
                <div >
                  <h3 className='text-center'>SGPA to CGPA History Graph</h3>
                  <Doughnut data={historyGraphData} />
                </div>
              )}
            </div>

            <div className='col-md-6'>
              <h3 className='text-center'>Saved History</h3>
              <div className='table-responsive'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Grading Scale</th>
                      <th>SGPA</th>
                      <th>CGPA</th>
                    </tr>
                  </thead>
                  <tbody>
                    {history.map((entry, index) => (
                      <tr key={index}>
                        <td>{entry.date}</td>
                        <td>{entry.scale}</td>
                        <td>{entry.sgpa}</td>
                        <td>{entry.cgpa}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button onClick={handleClearHistory} className='btn btn-danger mt-3'>
                  Clear History
                </button>
              </div>
            </div>

         
          </div>
        </div>
      )}
      <div className='container'>
        <div className='row' style={{marginTop:"50px"}}>
        <hr></hr>
        <h2 className='text-center text-black'>About SGPA to CGPA Calculator</h2>
        <hr></hr>
        <p style={{fontFamily:'sans-serif'}}>The SGPA to CGPA Calculator is a tool designed to convert your Semester Grade Point Average (SGPA) into Cumulative Grade Point Average (CGPA). It simplifies the process of calculating your overall academic performance across multiple semesters.</p>

        <h3>What is SGPA?</h3>
        <p style={{fontFamily:'sans-serif'}}>SGPA (Semester Grade Point Average) represents the average performance of a student in a specific semester. It is calculated by taking into account the grades obtained in all subjects within that semester and is often expressed on a scale such as 10.0 or 4.0.</p>

        <h3>What is CGPA?</h3>
        <p style={{fontFamily:'sans-serif'}}>CGPA (Cumulative Grade Point Average) is the overall GPA that reflects a student's academic performance over multiple semesters. It provides a cumulative score by averaging the SGPA scores from each semester, offering a broader view of the student's academic standing over time.</p>

        <h4>Why Convert SGPA to CGPA?</h4>
        <div style={{fontFamily:'sans-serif'}}>
          <ul>
            <li>Comprehensive Academic Overview: While SGPA reflects your performance in a single semester, CGPA offers a holistic view of your entire academic journey.</li>
            <li>Higher Education: Universities often require CGPA scores during applications for higher studies, such as master's programs, where cumulative performance is evaluated.</li>
            <li>Career Applications: Many employers consider CGPA as a more reliable measure of academic consistency, especially when looking at multiple semesters.</li>
            <li>Scholarships and Awards: CGPA is often used to determine eligibility for scholarships, academic awards, and other honors based on overall academic performance.</li>
          </ul>
        </div>

        <h4>Features of the SGPA to CGPA Calculator</h4>
        <div>
          <ul>
            <li>User-Friendly Input: Simply enter your SGPA for each semester, and the calculator will automatically compute your CGPA.</li>
            <li>Accurate Results: Provides precise CGPA by averaging the SGPA scores from all semesters.</li>
            <li>Supports Various GPA Scales: Works with different grading scales such as 10.0, 4.0, or others based on institutional standards.</li>
            <li>History Tracking: Allows students to track their academic progress semester by semester.</li>
          </ul>
        </div>

        <h4>Benefits of Using the SGPA to CGPA Calculator</h4>
        <div>
          <ul>
            <li>Quick and Accurate: The calculator eliminates the need for manual calculations, providing reliable CGPA results in seconds.</li>
            <li>Easy to Use: With straightforward input fields, students can easily convert their SGPA scores into CGPA without any hassle.</li>
            <li>Comprehensive Academic Insight: Helps students understand their overall academic performance by providing a cumulative score.</li>
            <li>Useful for Academic and Career Progression: Whether you're applying to universities, seeking scholarships, or job hunting, knowing your CGPA is essential for presenting your academic credentials.</li>
          </ul>
        </div>

        <p style={{fontFamily:'sans-serif'}}>The SGPA to CGPA Calculator is an essential tool for students, educators, and professionals who need to compute CGPA scores based on semester-wise performance. It offers a fast, accurate, and easy way to get your cumulative academic performance score, whether for personal tracking, university applications, or job prospects.</p>

        </div>
      </div>
          </div>
          <div className='col-md-3'>
            <Sidebar/>
          </div>
        </div>
      </div>

    </Layout>
  );
};

export default SGPAtoCGPA;
